// esllint-disable  react/no-unknown-property
import * as React from 'react'


export const FannieMaeContent = () => {
  return (
    <>
      <div className="modal fade" id="fannieMaeDetails" aria-labelledby="fannieMaeDetails" aria-hidden="true"
      data-modal-title="Fannie Mae">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-11 mx-auto">
                  <h5 className="modal-title text-xl mr-3 d-inline-block" id="fannieMaeDetailsTitle">FannieMae.com </h5>
                  <div className="text-center  modal-crumbs">
                    <span className="mx-2">/</span> Website <span className="mx-2">/</span> Games <span className="mx-2">/</span> 
                    Mortgage Finance <span className="mx-2">/</span></div>
                </div>
              </div>
            </div>
            
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
          </div>
          
          <div className="modal-body">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-12 mx-autopt--2">

                  <p className="hs-paragraph lead">
                    Design system to aid in consistancy of branding for a company with more than 40k employees.
                  </p>
                  <div className="hs-paragraph">
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Company</strong>:</div>
                      <div className="col-md-10">Fannie Mae</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Description </strong>:</div>
                      <div className="col-md-10">Enterprise Design System</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>My Roles</strong>:</div>
                      <div className="col-md-10">UI/UX Design <span className="hs-text-yellow">&#10038;</span> Lead UI/UX Developer <span className="hs-text-yellow">&#10038;</span> Interaction Design
                        <span className="hs-text-yellow">&#10038;</span> Quality Assurance</div>
                    </div>
                  </div>
                  </div>
                    <div className="hs-paragraph text-center pt-3">
                      <img src="/assets/img/portfolio/Fannie-Mae/blueprint-design-system-1200.jpg" alt="Blueprint Design System" width="100%" height="auto"/>
                      {/* <hs-flip2code-dev flipcode-language="javascript" flip-height="auto" flip-width="100%">
                        <div slot="front" className="flip2code-frontslot">
                          <hs-rotator3d data-gap="10" className="mt-5" mode="modal"></hs-rotator3d>
                        </div> */}
                        {/* <div id="codeblock01" className="flip2code-backslot" slot="back"> 
                          <fk-highlight-code
                            style="display: block; margin-left: 10%;"
                            line-numbers="true"
                            id="rotate3D"
                            terminal="none"
                            src="https://raw.githubusercontent.com/heathshults/heathshults-web-ui/master/src/js/inDev/toggler-system/toggler.js"
                          />
                      </div>
                      </hs-flip2code-dev>
                    </div> */}
                  </div>
              </div>
            </div>
          </div>
          </div>
        </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
          </div>
      </div>

    </>
  )
}
export default FannieMaeContent