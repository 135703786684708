import PropTypes from "prop-types"
import * as React from 'react';
import { Navbar, Nav, NavItem, NavbarToggler, NavbarText, Collapse, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import SignInOutButton from "components/SignInOutButton/SignInOutButton";
// import { EventBus } from '../../utils/EventBus';
import './navigate.scss';
// import css from './scss/Navigation.module.scss';

// interface NavigationProps {
//   className?: string;
//   children?: ReactNode[] | JSX.Element;
// }
// interface LinkExtendedProps extends LinkProps {
//   onClick: () => void;
// }

export function Navigation(props){
  const [headerFadeClass, setHeaderFadeClass] = React.useState('fixedTop fadeOut');
  const scrollStartHeight = 79;
  const {className='', children} = props
  // Link as LinkExtendedProps
  // const evtbus = new EventBus();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const toggle = React.useCallback(() => setIsMobileMenuOpen((prevState) => !prevState), []);

  React.useEffect(() => {
    // Solid background is displayed after scrolling for 79 pixels
    const handleIntersect = () => {
      console.log(window.pageYOffset);
      window.pageYOffset >= scrollStartHeight ? setHeaderFadeClass('fixedTop fadeIn') : setHeaderFadeClass('fixedTop fadeOut');
    };
    window.addEventListener('scroll', handleIntersect);

    // () => window.removeEventListener('scroll', handleIntersect);
  }, [window.pageYOffset, scrollStartHeight]);

  return (
    <>

      <Navbar className={`background-switcher w-100 bg-transparent ${className} ${headerFadeClass}`} expand="md">
      <NavbarBrand href="/" className="navbar-brand site-logo">
            <span className="hs-site-logo-symbol">@</span>
            <span className="site-logo-link">HeathShults.com</span>

        </NavbarBrand>
        <NavbarToggler id="navbarToggler" className="d-flex" onClick={toggle}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </NavbarToggler>
        <Collapse navbar className="collapser" isOpen={isMobileMenuOpen} toggler="#navbarToggler">
          <Nav className="justify-content-center main-nav" navbar>
            <NavItem>
              <Link className="nav-link" to="/skills">
                SKILLS
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/portfolio">
                PORTFOLIO
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/about">
                ME
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/contact">
                CONTACT
              </Link>
            </NavItem>
            {children}
          </Nav>
          <NavbarText className="theme-mode-widget-container">
            <SignInOutButton signInButtonText="Sign In" signOutButtonText="Sign Out" />
            {/* <button className="theme-mode-widget" id="mode_widget">
              <span className="which-mode">
                {' '}
                Light Mode<i id="mode_icon" className="fa fa-sun mode-icon"></i>
              </span>
            </button> */}
          </NavbarText>
        </Collapse>
      </Navbar>
    </>
  );
}

Navigation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Navigation;
