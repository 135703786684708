// import PropTypes from "prop-types"
import PropTypes from "prop-types"
import * as React from 'react';
import ErrorBoundary, { FallbackComponent } from '../../utils/ErrorBoundary'
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TimelineBlock from "./TimelineBlock";
import TimelineDotIcon from '../../components/TimelineDotIcon'



// export interface HeathsStoryProps {
//   children?: React.ReactNode;
// }


const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#232323', contrastText: '#212121' },
    secondary: {
      main: '#2e2e2e',
    },
  },
  components: {
    Timeline: {
      styleOverrides: {
        root: {
          color: '#2e2e2e',
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          width: 68,
          height: 68,
          textAlign: 'center',
          fontWeight: 'normal',
          backgroundColor: 'transparent',
        }
      }
    }
  }
});

export const HeathsStory = (props) => {
  const { children } = props;
  return (
    <>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <ThemeProvider theme={lightTheme}>
          <a href="#about"></a>
          <section id="about" className="bg-white about hs-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center text-color--light">
                  <p>
                  </p><h2 className="hs-section-heading bg-black--a75-opacity d-inline-block">Heath Shults</h2>
                  <p></p>
                  <h3 className="hs-section-subheading hs-text-muted pb-3 bg-black--a75-opacity d-inline-block">
                    ✶ Here is a little about me. ✶
                  </h3>
                </div>
              </div>
              {/* Begin Timeline */}
              <div className="row bg-black--a25-opacity p-3">
                <div className="col-lg-12">
                  <div className="d-block">
                    <TimelineDot color="warning">
                      <TimelineDotIcon iconClass="fa-solid fa-rocket"  year="2000" />
                    </TimelineDot>
                  </div>

                  <Timeline position="alternate">
                    <TimelineBlock
                      eventTitle="Sessions College of Professional Design"
                      eventMonth="Jan"
                      eventDay="01"
                      eventYear="2002"
                      eventIconClass="fa-solid fa-graduation-cap"
                    >
                      <div className="hs-quotes pb-3">
                        <p className="color-contrast-medium">So I thought &quot;This whole internet thing might just stick around for a while. If I&apos;m going to do this website thing,
                          perhaps I&apos;ll go all in and attend college online&quot;. <em>(using 128kbp dial-up and the net gateway of the early years - AOL :)</em></p>
                      </div>
                      <p className="hs-timeline__content-footer">Graphic Design <span className="hs-text-yellow">✶</span> Web Design <span className="hs-text-yellow">✶</span> New Media </p>

                    </TimelineBlock>

                    <TimelineBlock
                      eventTitle="Hired! My first real job."
                      eventMonth="Sept"
                      eventDay="05"
                      eventYear="2005"
                      eventIconClass="fa-solid fa-award"
                    >
                      <div className="hs-quotes">
                                  <p className="color-contrast-medium">I boarded as the "Web Guy". Then I was manager of web development. Till finally in 2008 I became Director of Technology. And then, my 11 year tenure flew right by.</p>
                                </div>
                                <p className="hs-timeline__content-footer">Web Development<span className="hs-text-yellow">✶</span> Director of Technology <span className="hs-text-yellow">✶</span> Full Stack IT Guru </p>

                    </TimelineBlock>
                    <TimelineBlock
                      eventTitle="Moving on to further my knowledge as an engineer"
                      eventMonth="March"
                      eventDay="15"
                      eventYear="2017"
                      eventIconClass="fa-solid fa-hand-peace"
                    >
                      <div className="hs-quotes">
                                  <p className="color-contrast-medium">UI/UX Engineer - Blue Star Sports<br/>
                                  Blue Star Sports was a startup company focused on youth football player tracking. 
                                  I Converted creative assets into functional interactive user interfaces and received my first 
                                  exposuer to React.js
                                  </p>
                                </div>
                                <p className="hs-timeline__content-footer">Graphic Design Conversion to HTML Markup<span className="hs-text-yellow">✶</span> focused on User Experience Engineering &amp; User Interaction Design<span className="hs-text-yellow">✶</span></p>
                    </TimelineBlock>

                    <TimelineBlock
                      eventTitle="Next up, Fannie Mae, Inc."
                      eventMonth="May"
                      eventDay="01"
                      eventYear="2018"
                      eventIconClass="fa-solid fa-house-chimney"

                    >
                      <div className="hs-quotes">
                                  <p className="color-contrast-medium">UI Engineer / Interactive Designer - Fannie Mae <br/>
                                    At Fannie Mae I had the pleasure of being a lead on a creative team that was tasked with developing 
                                    an entire Design System for the entire company to utilize in an effort to standardize best practices, 
                                    and establish consistency amoung the corporation of over 8000 employees. We called it Blue Print Design System. 
                                    We used JavaScript, SCSS/CSS and HTML5 on the front end and consumed REST APIs and JSON based data.
                                    to create UI and small micro apps for the design system.
                                  </p>
                                  <p>
                                    One of the most interesting things I learned was Design Thinking. I completed the LUMA certification and still utilize 
                                    LUMA's approach to solving problems to this day. 
                                  </p>
                                </div>
                                <p className="hs-timeline__content-footer">Design Standards &amp; Coding Best Practices<span className="hs-text-yellow">✶</span> Focused on User Experience Engineering &amp; User Interaction Design<span className="hs-text-yellow">✶</span></p>
                    </TimelineBlock>
                    
                    <TimelineBlock
                      eventTitle="Time for Mobile Apps - USAA"
                      eventMonth="May"
                      eventDay="01"
                      eventYear="2020"
                      eventIconClass="fa-solid fa-mobile-screen-button"
                      
                    >
                      <div className="hs-quotes">
                                  <p className="color-contrast-medium">UI Engineer - Employee Mobile Apps - USAA <br/>
                                    This was a great opportunity to gain exposure to mobile development. We used Angular and Ionic for the base of the libraries. We worked
                                    on 15 different apps and developed a COVID app to check employees for illness before reporting to work. We utilized Git Labs and Gradel for our 
                                    CI pipeline which compiled to both iOS and Android applications. 
                                  </p>
                                </div>
                                <p className="hs-timeline__content-footer">UI Engineer<span className="hs-text-yellow">✶</span> Focused on User Experience &amp; Design Thinking<span className="hs-text-yellow">✶</span></p>
                    </TimelineBlock>
                    
                    <TimelineBlock
                      eventTitle="AI & Machine Learning - Alfi, Inc."
                      eventMonth="May"
                      eventDay="01"
                      eventYear="2020"
                      eventIconClass="fa-solid fa-mobile-screen-button"
                      
                    >
                      <div className="hs-quotes">
                                  <p className="color-contrast-medium">Senior FrontEnd Software Engineer - More info coming soon...
                                  </p>
                                </div>
                                <p className="hs-timeline__content-footer">Team Lead <span className="hs-text-yellow">✶</span> Mentor <span className="hs-text-yellow">✶</span> Technologist</p>
                    </TimelineBlock>
                    
                  </Timeline>
                </div>
              </div>
              {/* End Timeline */}
            </div>
          </section>
          {children}
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

HeathsStory.propTypes = {
  children: PropTypes.node
}

// HeathsStory.propTypes = {
//   children: PropTypes.node
// }

export default HeathsStory;






