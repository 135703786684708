import PropTypes from "prop-types"
import * as React from 'react';
import css from './styled.module.scss';
import './header.scss';
import Navigation from 'components/Navigation/Navigation-2';
import {Outlet} from 'react-router-dom';

// export interface SingleColumnLayoutProps {
//   children?: React.ReactNode[] | JSX.Element;
//   className?: string | any;
// }

// export interface PageWrapProps {
//   children?: React.ReactNode[] | JSX.Element;
//   className?: string | any;
// }

// export interface HeaderProps {
//   children?: React.ReactNode[] | JSX.Element;
//   className?: string | any;
// }

// export interface FooterProps {
//   children?: React.ReactNode[] | JSX.Element;
//   classlist?: string;
//   height?: string;
// }

// export interface MainProps {
//   children?: React.ReactNode[] | JSX.Element;
//   className?: any[] | string;
// }

export function PageWrap({children}) {
  return (
    <>
      <div className={css.container100}>{children}</div>
    </>
  );
}

PageWrap.propTypes = {
  children: PropTypes.node
}

export function Header({children, className = ''}) {
  // const [verticalPosition, setVerticalPosition] = useState(false);
  const [headerFadeClass, setHeaderFadeClass] = React.useState('fixedTop');
  const scrollStartHeight = 79;
  const HeaderElement = () => {
    return (
      <>
        <header id="header" className={`container-fluid p-0 m-0 ${css.singleColumnLayoutHeader} ${className} fixedTop`}>
          {children}
        </header>
      </>
    );
  };

  React.useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const handleIntersect = () => {
      console.log(window.pageYOffset);
      window.pageYOffset >= scrollStartHeight ? setHeaderFadeClass('fixedTop') : setHeaderFadeClass('fixedTop');
    };
    window.addEventListener('scroll', handleIntersect);

    // () => window.removeEventListener('scroll', handleIntersect);
  }, [window.pageYOffset, scrollStartHeight]);

  return (
    <>
      <HeaderElement>{children}</HeaderElement>
      {/* <header id="header" ref={headerRef} className={`container-fluid p-0 m-0 ${css.singleColumnLayoutHeader} ${className} ${headerFadeClass}`}>
      { children }
    </header> */}
    </>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export function Main({children, className = ''}) {
  return (
    <>
      <main className={`${css.singleColumnLayoutContent} ${css.content} ${className}`}>{children}</main>
    </>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export function PageFooter({children, classlist = '', height = '0px'}) {
  const [datetime /*setDateTime*/] = React.useState(new Date().getFullYear());
  return (
    <>
      <footer className={`footer hs-section ${classlist}`} style={{height: height}}>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <span className="copyright">
                Copyright &copy; HeathShults.com
                {datetime}
              </span>
            </div>
            <div className="col-md-4">
              <ul className="list-inline social-buttons">
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-inline quicklinks">
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-5">
              <p style={{fontSize: '1.5rem', fontWeight: 700}}>
                <a href="http://www.freepik.com">THANK YOU Freepik!</a>
              </p>
            </div>
          </div>
        </div>
        {children}
      </footer>
      {/* <footer ref={footerRef} style={{bottom: -offsetFooterHeight}} className={`${css.singleColumnFooter } ${verticalPosition.cssClass} ${className}`}> */}
      {/* <footer className={`${css.singleColumnLayoutFooter} ${ftcss.hsFooter} ${className}`} style={{height: height}}>
      { children }
    </footer> */}
    </>
  );
}

PageFooter.propTypes = {
  children: PropTypes.node,
  classlist: PropTypes.string,
  height: PropTypes.string
}

export function SingleColumnLayout(props) {
  return (
    <>
      <PageWrap>
        <Main className={`${css.content} container-fluid`}>
          {/* { children } */}
          <div className="header-container">
            <Header className="">
              <Navigation />
            </Header>
          </div>
          <Outlet />
        </Main>
        <PageFooter classlist={`${css.footer} d-none container-fluid`}>
          {props.children}
        </PageFooter>
      </PageWrap>
    </>
  );
}

SingleColumnLayout.propTypes = {
  children: PropTypes.node
}

export default SingleColumnLayout;
