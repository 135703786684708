/* eslint-disable no-redeclare */
import * as React from 'react'
import { SignInButton } from 'components/SignInButton'
import axios from 'axios';
import config from '../../contexts/configContext'




export const SignIn = () => {
  const [accessToken, setAccessToken] = React.useState('')
  const gvars = React.useContext(config)
  console.log(gvars)

  function handleClick() {
    let data = JSON.stringify({
      "client_id": "mESMS71zfx3e5ypEP8TP26z53AFsbIyU",
      "client_secret": "8wGNapWv8QWhkkXCMR9C2YSVVK2Wg_WlCNdQtHClKhjT0SvtI-cTsJ-L13jha4bK",
      "audience": "https://heathshults.com/api",
      "grant_type": "client_credentials"
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://dev-hu29zoup.us.auth0.com/oauth/token',
      headers: {
        'content-type': 'application/json',
        'Cookie': 'did=s%3Av0%3Af70d04a0-eb2b-11ed-9b13-8f008dd55df6.q41cTzS%2F6HQV2wZdU%2FfB%2FVirb9DHMWrG%2FSkxwttNjXs; did_compat=s%3Av0%3Af70d04a0-eb2b-11ed-9b13-8f008dd55df6.q41cTzS%2F6HQV2wZdU%2FfB%2FVirb9DHMWrG%2FSkxwttNjXs'
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      setAccessToken(JSON.stringify(response.data.access_token))
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });

  }

    return (<>
      <div style={{height: '100vh', display: 'flex', color: '#fff', alignItems: 'center', justifyContent: 'center'}}>
        <SignInButton buttonText='Sign In'/>
        <button onClick={handleClick}>Test</button>
        {accessToken ? JSON.stringify(accessToken): null}
      </div>
    </>)
}

export default SignIn
