import React from 'react';
import '../../views/HeathsStory/HeathsStoryStyles.scss'

export interface TimelineDotIconProps {
    iconClass: string
    year: string
}

function TimelineDotIcon({ iconClass, year}: TimelineDotIconProps) {
    return (<>
        <span className="timeline-icon__container" style={{minWidth: 68, minHeight: 68}}>
            {/* <span style={{ backgroundImage: `url(${path})`, backgroundSize: width - 10, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', paddingTop: '50%', width: width - 10, height: height - 10 }}>
            </span> */}
            <span className="timeline-icon">
                <i className={iconClass}></i>
            </span>
            {/* <img src={path} style={{width: 48,  height: 48}} alt="Timeline icon" className="timeline-icon"/> */}
            
        </span>
    </>);
}
 

export default TimelineDotIcon;
