import * as React from 'react'
import axios, {AxiosInstance}  from 'axios'

interface iGlobalV {
  children: React.ReactNode
}

interface iAuth {
  client_id?: string
  client_secret?: string
  audience?: string
  grant_type?: string
  access_token_url?:string
}

interface iGlobalConfig {
  goFetch?: AxiosInstance
  auth?: iAuth
  apiurl?: string
}

export const fetcher = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000,
  headers: {'X-Custom-Header': 'foobar'}
});


export const config: iGlobalConfig = {
  goFetch: fetcher,
  auth: {
    access_token_url: process.env.REACT_APP_AUTH0_API_ACCESS_TOKEN_URL,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
    grant_type: 'client_credentials'
  },
  apiurl: process.env.REACT_APP_API_URL

}
console.log(config)

export const GlobalVContext = React.createContext(config)
export const GlobalVContextProvider = ({children}: iGlobalV) => {
  const Global:iGlobalConfig = config
  return <GlobalVContext.Provider value={Global}>{children}</GlobalVContext.Provider>
}
export default GlobalVContextProvider
