import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from "@auth0/auth0-react";
// import './index.scss';
// import './scss/styles.scss';
import WebFont from 'webfontloader';
import App from './App';
import reportWebVitals from './reportWebVitals';
WebFont.load({
  custom: {
    families: [
      'Caveat',
      'MMontserrat',
      'Nothing You Could Do',
      'Roboto',
      'Source Code Pro'

    ],
    urls: [
      './scss/variables/_fonts.scss',
      'https://fonts.googleapis.com/css2?family=Caveat:wght@400;600&family=Montserrat:wght@400;600;700&family=Nothing+You+Could+Do&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap',
      'https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,400;1,200;1,400&display=swap'
    ]
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain="dev-hu29zoup.us.auth0.com"
    clientId="8nb7qce5m4cKwkL6nyreStWKY0mR3R9t"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
