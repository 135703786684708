import React from 'react';
// import FormThemeProvider from './styles';
import axios, {AxiosResponse} from 'axios';
import PropTypes from 'prop-types'

// interface ContactProps {
//   children?: ReactNode;
// }

// interface FormElements extends HTMLFormControlsCollection {
//   nameInput: HTMLInputElement
//   emailInput: HTMLInputElement
//   phoneInput: HTMLInputElement
//   messageInput: HTMLInputElement
// }

// interface InputFormElement extends HTMLFormElement {
//   readonly elements: FormElements
// }

// interface SubmitButtonProps {
//   buttonText: string
//   onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>
//   cssClasses?: string
//   dataIcon: string
// }

// interface FetchCall extends AxiosResponse<any, any> {
//   acknowledged: boolean;
// }

export function ContactForm() {
  const [isSending, setIsSending] = React.useState(false);
  const [showForm, setShowForm] = React.useState(true);
  const [buttonContent, setButtonContent] = React.useState('Send Message');
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');
  const formRef = React.useRef();
  const responseMessageElement = React.useRef();
  const submitButton = React.useRef();

  function messageChangeHandler(event) {
    console.log('textarea', event.currentTarget.value);
    setTextAreaValue(event.currentTarget.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSending(true);
    setButtonContent('Sending...');
    const formData = new FormData(event.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());
    console.log('data', fieldValues);

    async function postData(url: string, data: Record<string, any>) {
      const response = await axios.post(url, {
        'Content-Type': 'application/json',
        body: JSON.stringify(data),
      });
      console.log('response', response);
      return response;
    }

    postData('http://localhost:3609/api/contact/message.php', {fieldValues}).then((data) => {
      console.log('node express returned: \n' + JSON.stringify(data)); // JSON data parsed by `data.json()` call
      data.acknowledged ? successOperation(data) : failedOperation(data);
    });
  }

  function successOperation(data) {
    try {
      setIsSending(false);
      setShowForm(false);
      setResponseMessage('Thank you for your message. I will get back to you asap.');
      const successMessage = React.createElement(
        'div',
        {className: 'response-message--failed'},
        responseMessage
      );
      responseMessageElement.current.appendChild(successMessage);
    } catch (err) {
      setIsSending(false);
      setShowForm(false);
      failedOperation(data);
    }
  }

  function failedOperation(data) {
    try {
      setIsSending(false);
      setShowForm(false);
      setResponseMessage(
        'There was an error sending your message. Please try again. If you continue to receive an error please email heath@heathshults.com'
      );
      const failedMessage = React.createElement(
        'div',
        {className: 'response-message--failed'},
        responseMessage
      );
      responseMessageElement.current.appendChild(failedMessage);
    } catch (err) {
      setIsSending(false);
      setShowForm(false);
      setResponseMessage(
        'There was an error sending your message. Please try again. If you continue to receive an error please email heath@heathshults.com'
      );
      const failedMessage = React.createElement(
        'div',
        {className: 'response-message--failed'},
        responseMessage
      );
      responseMessageElement.current.appendChild(failedMessage);
      console.error(err);
    } finally {
      setIsSending(false);
      setShowForm(false);
    }
  }

  function valuetext(value: number) {
    return `${value}`;
  }

  function textAreaFix() {
    const txtBox= document.querySelector('textArea#message');
    setTimeout(() => {
      txtBox.setAttribute('style', 'width: 100%;');
    }, 500);
  }

  function SubmitButton(props) {
    const {buttonText, dataIcon, cssClasses} = props

    return (
      <button
        type="submit"
        ref={submitButton}
        id="submitButton"
        className={cssClasses}
      >
        {buttonContent} {isSending ? <i className={dataIcon} /> :null}
      </button>
    );
  };
  SubmitButton.propTypes = {
    cssClasses: PropTypes.string,
    buttonText: PropTypes.string,
    dataIcon: PropTypes.string,
  }
  React.useEffect(() => {
    textAreaFix();
  }, []);

  return (
    <>
      {/* <FormThemeProvider> */}
        <section id="contact" className="hs-contact hs-section pt-5">
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-12 text-center text-color--light">
                <h2 className="hs-section-heading">Contact Me Form </h2>
                <h3 className="hs-section-subheading hs-text-muted">&#10038; Get the job done right the first time. &#10038;</h3>
              </div>
              {/* <h2>Just started redoing this form. It's inactive now but if you need to get in touch - heatheshults@gmail.com</h2> */}
              {showForm && !isSending ? (
                <form ref={formRef} onSubmit={handleSubmit} name="sendMessage" data-autocomplete="off" id="ezform" className="mt-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <div className="row">
                          <div className="col w-50">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                autoFocus
                                placeholder="First Name"
                                className="form-control"
                                id="firstname"
                                name="firstname"
                                aria-label="First Name"
                                required
                              />
                              <span className="input-group-text">
                                <i className="fa fa-user" />
                              </span>
                            </div>
                          </div>

                          <div className="col">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                autoFocus
                                className="form-control"
                                placeholder="Last Name"
                                id="lastname"
                                name="lastname"
                                aria-label="Last Name"
                                required
                              />
                              <span className="input-group-text">
                                <i className="fa fa-user" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col w-100">
                            <div className="input-group mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                aria-label="Email"
                                required
                                placeholder="Email"
                              />
                              <span className="input-group-text">
                                <i className="fa fa-at" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col w-100">
                            <div className="input-group mb-3">
                              <input
                                type="phone"
                                className="form-control"
                                id="phone"
                                name="phone"
                                aria-label="Phone"
                                required
                                placeholder="Phone"
                              />
                              <span className="input-group-text">
                                <i className="fa fa-mobile" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-6 d-flex align-items-stretch">
                      <div classsName="row" style={{width: '100%'}}>
                        <div className="input-group text-right h-100 mb-3"
                          style={{width: '100%', justifyContent: 'right'}}>
                          <textarea
                            id="message"
                            name="message"
                            className="input-textarea"
                            onChange={messageChangeHandler}
                            value={textAreaValue}
                            aria-label="message"
                            placeholder="Leave a message!"
                            style={{width: '100%'}}
                            rows={4}
                          >
                          </textarea>
                          <span className="input-group-text">
                            <i className="fa fa-comment-dots" />
                          </span>
                        </div>
                        </div>
                      </div>
                      <div className="row m-0">
                      <div className="col-md-12 h-100 mb-3 mx-0 p-0">
                          <SubmitButton
                            buttonText={buttonContent}
                            dataIcon="fa fa-spinner fa-spin"
                            cssClasses="btn btn-dark mt-2 border border-2 border-light contact-button"

                          />
                        </div>
                      </div>
                    </div>
                  </div>
              </form>
              ) : (
                <div className="row">
                  <div ref={responseMessageElement} className="col w-100"></div>
                </div>
              )}
            </div>
          </div>
        </section>
      {/* </FormThemeProvider> */}
    </>
  );
}

export default ContactForm;
