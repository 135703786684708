import React from 'react';
// import ParticleEffectButton from 'react-particle-effect-button';
import './Home.scss';
import { fetcher } from '../../contexts/configContext'

export const Home = () => {
  // const [hidden, setHidden] = React.useState();
  // userService.getAll().then((users) => this.setState({ users }));

  const response = fetcher.post('localhost:8443/api/',{

  })

  return (
    <>
      <section id="header" className={`d-flex vh-100 align-items-center home-greeting px-0`}>
        <div className="container-fluid">
          <div className="hs-intro-text">
            <div id="greetingLine" className="greeting-container">
              <div className="inline-block mx-auto hs-intro-heading-wrap">
              <h2 className="hs-intro-heading">
                Welcome
              </h2>

              </div>

                <div className="hs-intro-subheading-01">
                  <div>
                    <span className="sub-heading-01">
                      To My
                    </span>
                  </div>
                </div>

                {/* <div className="hs-divider-h4"></div> */}
                <div className="hs-intro-subheading-03">
                  <div>
                    <span className="sub-heading-03">
                      PLAYGROUND
                    </span>
                  </div>
              </div>
            </div>
            <div>
              <a href="/skills" className="js-page-trigger greeting-button btn btn-xl">
                TAKE A LOOK INSIDE
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
