import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import './HSRater.scss'

interface iHSRater {
  ratingName: string
  ratingValue: number | null
  ratingWidth: number | null
  isReadOnly?: boolean
  logoPath?: string | null
  logoWidth?: number | string | null
  logoHeight?: number | string | null
}


export function HSRater({ratingName, ratingValue, ratingWidth=150, isReadOnly=false, logoPath, logoWidth='auto', logoHeight=80}: iHSRater) {
  const [value, setValue] = React.useState<number | null>(ratingValue);
  const [hover, setHover] = React.useState(-1);

  const labels: { [index: string]: string } = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  return (
    <>
      {value !== null && (
        <Box sx={{ ml: 2 }}>
          <img src={logoPath} className="rater-image" alt={`${ratingName} logo`} style={{width: logoWidth, height: logoHeight, marginRight: 6}} />
          {/* {labels[hover !== -1 ? hover : value]} */}</Box>
      )}

      <Box
        sx={{
          width: ratingWidth,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Rating
          name={ratingName}
          value={ratingValue}
          precision={0.5}
          getLabelText={getLabelText}
          readOnly={isReadOnly}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />

      </Box>
    </>
  );
}

export default HSRater
