import * as React from 'react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import portfolioImages from './portfolio-images.json'
import ErrorBoundary from 'utils/ErrorBoundary'
import PortfolioImages from './portfolio-images.json'
import { TPortfolioImages } from 'components/components.d'
import './PortfolioGallery.scss'

// import styles from './PortfolioGallery.module.css';

const darkTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
  typography: {
    allVariants: {
      color: 'white'
    },
  },
  components: {
    // @ts-expect-error
    MuiMasonry: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: 1280,
          margin: 'auto',
          border: '2px solid #0a0e12 !important',
          borderRadius: '.375rem',
          backgroundColor: '#0b1014 !important',
        }
      }
    },
  }
});


// interface PortfolioGalleryProps {
//   children?: React.ReactNode
// };

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
}));

export const PortfolioGallery = () => {
  // const [show, setShow] = React.useState(false);
  const [sectionHeight, setSectionHeight] = React.useState<number>();
  const [sectionWidth, setSectionWidth] = React.useState<number>();
  // const [pageHeight, setPageHeight] = React.useState<number>();
  // const [pageWidth, setPageWidth] = React.useState<number>();
  const [galleryImageData, setGalleryImageData] = React.useState<TPortfolioImages>();
  const [loading, setLoading] = React.useState(false);
  const ProjectsSectionRef = React.useRef<HTMLElement>()
  // const portfolioGallery:any = portfolioImages;

  React.useEffect(()=>{
    let pageContainer: any ;

    async function getPortfolioImages() {
      setLoading(true)
      // const response = await fetch("./portfolio-images.json");
      // const jsonData = await response.json();
      // console.log(jsonData);
      setGalleryImageData(portfolioImages)
      setLoading(false)
    }

    if (typeof ProjectsSectionRef.current !== 'undefined') {
      pageContainer= ProjectsSectionRef.current;
      setSectionWidth(window.innerHeight);
      setSectionHeight(window.innerHeight);

    }

    if (typeof pageContainer !== 'undefined' && pageContainer.classList.contains('project-gallery') ) {
       pageContainer.style.width = sectionWidth;
       pageContainer.style.height = sectionHeight;
    }
    getPortfolioImages()
  }, [galleryImageData, sectionWidth, sectionHeight])

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <ErrorBoundary>
      <section ref={ProjectsSectionRef} id="ProjectGallery" className="project-gallery hs-section text-center">
        <h1 className="portfolioGalleryHeading mb-3">Portfolio Gallery</h1>
        {loading ? <span className="loadingSpinner">Loading...</span> :
        <Box sx={{ width: '100%', maxWidth: 1280, minHeight: 829, marginLeft: 'auto', marginRight: 'auto' }}>
          <Masonry columns={3} spacing={1}>
            {!galleryImageData ? void(0) : galleryImageData.map((item, index) => (
              <div key={index} className="text-center">
                {/* <Label>{`${item.title} ${index + 1}`}</Label> */}
                <Label>{`${item.title}`}</Label>
                <img
                  src={`${item.img}?w=300&auto=format`}
                  srcSet={`${item.img}?w=300&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                  style={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderRadius: 0,
                    display: 'block',
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            ))}
          </Masonry>
        </Box>
        }
      </section>
      </ErrorBoundary>
    </>
  );
};


export default PortfolioGallery
