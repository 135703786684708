import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface SignInButtonProps {
    buttonText: string
}

export const SignInButton = ({buttonText}: SignInButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
        <button onClick={() => loginWithRedirect()}>
            {buttonText} 
        </button>
    </>)
}

export default SignInButton;