import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import TimelineDotIcon from "components/TimelineDotIcon/TimelineDotIcon";
// import Stack from '@mui/material/Stack';
// import Box from '@mui/material/Box';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';


export interface iTimelineBlock {
  children: React.ReactNode
  eventMonth: string
  eventDay: string
  eventYear: string
  eventIconClass?: string
  eventTitle: string

}


export const TimelineBlock = ({ 
  children, 
  eventMonth, 
  eventDay, 
  eventYear, 
  eventTitle, 
  eventIconClass='hs-timeline__img--rocket'}: iTimelineBlock): any => {
  const lightTheme: ThemeOptions = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#232323', contrastText: '#212121' },
      secondary: {
        main: '#2e2e2e',
      },
    },
    components: {
      // @ts-expect-error
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            display: 'inline-flex',
            width: 68,
            height: 68,
            borderRadius: '50%',
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontWeight: 'bold',
            // backgroundImage: `url(${iconPath} no-repeat center center)`,
            // backgroundSize: '32px 32px',
          }
        }
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            width: 4,
            
          }
        }
      }
    }
  });

  return (<>
    <ThemeProvider theme={lightTheme}>

    
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="left"
        variant="body2"
        color="text.light"
      >
        {`${eventMonth} ${eventDay}, ${eventYear}`}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        <TimelineDot color="warning">
          <TimelineDotIcon iconClass={eventIconClass}  year={eventYear} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <div className="hs-timeline__block">
          <div className="hs-timeline__content text-component hs-timeline__content--bounce-in p-0">
            <div className="hs-timeline__content-padding p-3">
              <Typography variant="h4" component="h4" mb={3}>
                {eventTitle}
              </Typography>
              <Typography component="div" sx={{color: '#333',}}>
                {children}
                <span className="timeline-icon__text"></span>
                <span className="hs-timeline__date">{`${eventMonth} ${eventDay}, ${eventYear}`}</span>
                {/* <a href="#0" className="btn btn--subtle">Read more</a> */}

              </Typography>
            </div>
            <div className="hs-ripped-edge--bottom"></div>
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
        </ThemeProvider>
  </>)
}
export default TimelineBlock