import React from 'react';
import Card from '@mui/material/Card';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import HSRater from 'components/HSRater'
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import css from './skills-styles.module.scss';


const Item = styled(Paper)(({theme}) => ({
  backgroundColor: 'rgba(255,255,255, 0)',
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));




export function Skills() {

  return (
    <>
    <a href="#skills"></a>
      <section id="skills" className={`${css.hsSkills} hsSkills`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center text-color--light ">
              <span className="pt-5 mx-auto" style={{height: 50}}>
                &nbsp;
              </span>
              <h2 className={`${css.hsSectionHeading} mx-auto text-center d-inline`}>MY SCORE CARD </h2>
              <div className="text-center">
                <h3 className={`${css.hsSectionSubheading} font-bold-regular text-center`}>
                  <span className={`${css.giantText} mx-auto`}>[</span> Self Proclaimed Scoring <span className={css.giantText}>]</span>
                </h3>
              </div>
            </div>
          </div>

        </div>
        <div className="container">
        <Card classes={`${css.blurredGlassBg} noShadowInstance mb-4 bg-transparent`}>
        <Typography variant="h4" component="div"  pt={2} >
        My Top Development Skills
        </Typography>
      <CardContent classes={css.blurredGlassBg}>
        <div className="content-area">
          <div className="row">
            <div
              className={`col-lg-6 skills-list text-center pb-5 ${css.blurredGlassBgLeft}`}
              style={{paddingBottom: '1rem', width: '49%', marginRight: '4px'}}>
                <Stack direction="row" spacing={2}>
              <Box sx={{width: '100%', minWidth: 0, paddingTop: 2,}}>
                  <Item>
                    <HSRater ratingName="JavaScript/TypeScript" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/js-logo.jpg"/>
                  </Item>
                  <Item>
                    <HSRater ratingName="React" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/React_logo.png"/>
                  </Item>
                </Box>

                <Box sx={{width: '100%', minWidth: 0, paddingTop: 2,}}>
                  <Item>
                    <HSRater ratingName="HTML5" ratingValue={5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/dmHTML5_Logo_512.png"/>
                  </Item>
                  <Item>
                    <HSRater ratingName="SCSS/CSS" ratingValue={5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/Sass-150.png"/>
                  </Item>
              </Box>
                </Stack>
            </div>
            <div
              className={`col-lg-6 skills-list pt-0 ${css.blurredGlassBgRight}`}
              style={{paddingBottom: '1rem', width: '50%', marginLeft: '4px'}}>
              <Box sx={{width: '100%', paddingTop: 2,}}>
                <Stack direction="row" spacing={2}>
                  <Item>
                    <HSRater ratingName="NODE.js" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/Node.js_logo.png"/>
                  </Item>
                  <Item>
                    <HSRater ratingName="Webpack" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/webpack-javascript-logo.png"/>
                  </Item>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                  <Item>
                    <HSRater ratingName="Angular" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/angularJS_logo.png"/>
                  </Item>
                  <Item>
                    <HSRater ratingName="PHP" ratingValue={3.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/php.png"/>
                  </Item>

                </Stack>
              </Box>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
        </div>
    <div className="container mt-3">
        <Card key="databases" classes={`${css.blurredGlassBg} noShadowInstance mb-4`} >
            <Typography gutterBottom variant="h4" component="div" pt={2}>
              Database Skills
            </Typography>
            <CardContent classes={`${css.blurredGlassBg} w-100`}>
            <Box sx={{width: '100%', miWidth: 0, paddingTop: 2, margin: 'auto',  display: 'flex'}}>
                <Stack direction="row" spacing={2}>
                  <Item>
                    <HSRater ratingName="MS SQL" ratingValue={4} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/dm-sql-server.png"/>
                  </Item>
                  <Item>
                  <HSRater ratingName="MySQL" ratingValue={3.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/dm-mysql.png"/>
                  </Item>


                  <Item>
                  <HSRater ratingName="MongoDB" ratingValue={3.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/mongodb.png"/>
                  </Item>
                </Stack>
              </Box>
            </CardContent>
          </Card>
<div className="pt-3">
          <Card key="additionalSkills" classes={`${css.blurredGlassBg} noShadowInstance mb-4`} >
            <Typography gutterBottom variant="h4" component="div" pt={2}>
              Additional Skills
            </Typography>
            <CardContent classes={`${css.blurredGlassBg} w-100`}>
            <Box sx={{width: '100%', paddingTop: 2, margin: 'auto', display: 'inline-flex'}}>
                <Stack direction="row" spacing={2}>
                  <Item>
                    <HSRater ratingName="Gulp" ratingValue={4.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/gulp.svg"/>
                  </Item>
                  <Item>
                    <HSRater ratingName="Markdown" ratingValue={3.5} ratingWidth={200} isReadOnly={true} logoPath="/assets/img/logos/markdown-150.png"/>
                  </Item>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          </div>
          </div>

      </section>
    </>
  )
}

export default Skills
