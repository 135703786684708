import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SingleColumnLayout from 'views/_layouts/SingleColumnLayout'
// import Landing from 'views/Landing'
// import AppHome from 'views/AppHome'
// import Code from 'views/Portfolio/Code'
import './App.css'
import Home from 'views/Home'
import Skills from 'views/Skills'
import Portfolio from 'views/Portfolio'
import ErrorBoundary from './utils/ErrorBoundary'
import HeathsStory from 'views/HeathsStory'
import Contact from 'views/Contact'
import SignIn from 'views/SignIn'
import GlobalVContextProvider from './contexts/configContext'
// const Skills = React.lazy(() => import('views/Skills'))
// const Portfolio = React.lazy(() => import('views/Portfolio'))
// const HeathsStory = React.lazy(() => import('views/HeathsStory'))
// const Contact = React.lazy(() => import('views/Contact'))
// const Dashboard = React.lazy(() => import('views/Dashboard'))


function App() {
  return (
    <>

      <ErrorBoundary>
        <GlobalVContextProvider>
          <Routes>
            <Route element={<SingleColumnLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/skills" element={<Skills />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/about" element={<HeathsStory />} />
              <Route path="/contact" element={<Contact />} />
              {/*<Route path="/dashboard" element={<Dashboard />} />
              <Route path="/landing" element={<Landing />} /> */}
              <Route path="/about" element={<HeathsStory />} />
              <Route path="/signIn" element={<SignIn />} />
              <Route
                path="/contact"
                element={
                  <Contact />
                }
              />
              {/*  <Route
                path="/skills"
                element={
                  <React.Suspense fallback={<>...</>}>
                    {' '}
                    <Skills />{' '}
                  </React.Suspense>
                }
              />
              <Route
                path="/portfolio"
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Portfolio />
                  </React.Suspense>
                }
              /> */}
              {/* <Route path="/portfolio/code" element={<Code />} /> */}
              {/* <Route
                path="/dashboard"
                element={
                  <React.Suspense fallback={<>...</>}>
                    {' '}
                    <Dashboard />{' '}
                  </React.Suspense>
                }
              />*/}
            </Route>
          </Routes>
        </GlobalVContextProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
