import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import '../Navigation/navigate.scss';


export interface SignInOutButtonProps {
    signInButtonText: string;
    signOutButtonText: string;
    classes?: string;
}


function SignInOutButton({
    signInButtonText,
    signOutButtonText,
    classes
 }: SignInOutButtonProps) {
  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();
  
  function handleButtonClick() {
    !isAuthenticated ? loginWithRedirect() : logout({ logoutParams: { returnTo: window.location.origin } })
  }
  console.log('user obj', user)
  return (<>
    <span className='userMessage'>{!isAuthenticated ? '' : `Welcome ${user.given_name}!`}</span>
    <button onClick={handleButtonClick} className={`theme-mode-widget ${classes}`} id="mode_widget">
      <span className="which-mode">
        {' '}
        {!isAuthenticated ? signInButtonText : signOutButtonText}<i id="mode_icon" className="fa fa-sun mode-icon"></i>
      </span>
    </button>
  </>)
}

export default SignInOutButton;
