// esllint-disable  react/no-unknown-property
import * as React from 'react'


export const BowlopolisContent = ()=> {
  return (
    <>
      <div className="modal fade" id="bowlopolisDetails" aria-labelledby="bowlopolisDetails" aria-hidden="true" title="Bowlopolis">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-11 mx-auto">
                  <h5 className="modal-title text-xl mr-3 d-inline-block" id="bowlopolisDetailsTitle">Bowlopolis.com </h5>
                  <div className="text-center modal-crumbs">
                    <span className="mx-2">/</span> Website <span className="mx-2">/</span> Games <span className="mx-2">/</span> 3D
                    Cartoons videos <span className="mx-2">/</span></div>
                </div>
              </div>
            </div>
            
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
          </div>
          
          <div className="modal-body">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-12 mx-auto">

                  <p className="hs-paragraph lead">
                    Web design/development &amp; game development for retention/frequency.
                  </p>
                  <div className="hs-paragraph">
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Company</strong>:</div>
                      <div className="col-md-10">Bowling Proprietors' Association of America</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Description </strong>:</div>
                      <div className="col-md-10">Kids program designed to build lifetime bowling customers through games
                        videos, kids club, tracking  </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>My Roles</strong>:</div>
                      <div className="col-md-10">UI/UX Design <span className="hs-text-yellow">&#10038;</span> UI Developer 
                        <span className="hs-text-yellow">&#10038;</span> Quality Assurance</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 hs-paragraph text-center pt-3">
                      {/* <!-- hs-rotator3d --> */}
                      
                      <img src="/assets/img/portfolio/bowlopolis/episodes-page2.jpg" alt="Bowlopolis" width="100%" height="auto" />
                      {/* <!-- /hs-rotator3d --> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default BowlopolisContent 