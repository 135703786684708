// esllint-disable  react/no-unknown-property
import * as React from 'react';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// interface ModalProps {
//   handleOpen: any, 
//   handleClose: any
// }

export const BlueStarSportsContent = (
//   {
//   handleOpen = () => setOpen(true), 
//   handleClose = () => setOpen(false)
// }: ModalProps
) => {
  // const [open, setOpen] = React.useState(false);

  return (
    <>
    {/* <div> */}
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}> */}
      <div className="modal fade" id="bluestarDetails"aria-labelledby="bluestarDetails" aria-hidden="true" 
      data-modal-title="Blue Star Sports">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <h5 className="modal-title text-xl mr-3" id="bluestarTitle">Blue Star Sports
                    <div className="text-center"
                      style={{display: 'inline-block', flex: '1 1 auto', marginLeft: 20, fontSize: 14}}>
                      Youth Athlete Tracking System 
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
          </div>
          <div className="modal-body">
            
            <div className="container-sm modal-info-bar-container">
              <div className="row modal-info-bar">
                <div className="col-md-12 mx-auto">

                  <p className="hs-paragraph lead">
                    Progressive Web App Design &amp; Development for youth league football.
                  </p>
                  <div className="hs-paragraph">
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Company</strong>:</div>
                      <div className="col-md-10">Blue Star Sports</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Description</strong>:</div>
                      <div className="col-md-10"> Youth athlete management and stats tracking. </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>My Roles</strong>:</div>
                      <div className="col-md-10">UI Engineer <span className="hs-text-yellow">&#10038;</span> Product Design &amp; Prototyping <span className="hs-text-yellow">&#10038;</span>
                        </div>
                    </div>
                  </div>

                  
                </div>
                <p className="hs-paragraph text-center">
                  <img className="img-fluid img-centered" src="../assets/img/portfolio/BlueStarSports/collage-of-stuff-blue-star-sports.jpg" alt="blue star sports stuff"/>
                </p>
              </div>
            </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
      {/* </Box>
      </Modal> */}
      {/* </div> */}
      </>
  )
}

export default BlueStarSportsContent