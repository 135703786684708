// import PropTypes from "prop-types"
import React from 'react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import css from './Portfolio.module.scss'
import BlueStarSportsContent from './modals/blue-star-sports'
import BowlopolisContent from './modals/bowlopolis'
import FannieMaeContent from './modals/fannie-mae'
import USAAContent from './modals/usaa'
import PortfolioGallery from 'components/PortfolioGallery'


// interface PortfolioProps {
//   children?: React.ReactNode;
//   handleClose?: MouseEvent;
//   handleShow?: MouseEvent;
//   onHide?: Event;
// }
const darkTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
  typography: {
    allVariants: {
      color: 'white'
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:not(:first-of-type")': {
            marginLeft: '1rem',
          },
          color: '#fff',
          border: '2px solid #0a0e12 !important',
          borderRadius: '.375rem',
          backgroundColor: '#0b1014 !important',
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&:not(:first-of-type")': {
            marginLeft: '1rem',
          },
          color: '#fff',
          border: '2px solid #000000',
          borderRadius: '.375rem',
          backgroundColor: '#182028 !important',
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          width: '100%',
          padding: '.875rem',
          backgroundColor: '#0b1014'
        }
      }
    }
  }
});


const Portfolio = () => {
  // const [show, setShow] = React.useState(false);
  const [sectionHeight, setSectionHeight] = React.useState<number>(500);
  const [sectionWidth, setSectionWidth] = React.useState<number>(parseInt('100%'));
  // const [pageHeight, setPageHeight] = React.useState();
  // const [pageWidth, setPageWidth] = React.useState();
  // const portfolioSectionRef = document.querySelector('#portfolio');
  const portfolioSectionRef = React.useRef();
  const cardRowRef = React.useRef<HTMLDivElement>()
  const [cardHeight, setCardHeight] = React.useState<number>()

  const getSectionWidth = () => {
    setSectionWidth(window.innerHeight);
    // return window.innerWidth;
  }

  const getSectionHeight = () => {
    setSectionHeight(window.innerHeight);
    // return window.innerHeight;
  }

  React.useEffect(() => {
    let pageContainer;
    document.addEventListener('resize', () => {
      getSectionWidth()
      getSectionHeight()
    })
    if (typeof portfolioSectionRef !== 'undefined') {
      pageContainer = portfolioSectionRef.current;
      setSectionWidth(window.innerHeight);
      setSectionHeight(window.innerHeight);
      // setSectionWidth(getSectionWidth());
      // setSectionHeight(getSectionHeight());
    }

    if (typeof pageContainer !== 'undefined' && pageContainer.classList.contains('hs-portfolio')) {
      pageContainer.style.width = sectionWidth;
      pageContainer.style.height = sectionHeight;
      // pageContainer.style.width = getSectionWidth();
      // pageContainer.style.height = getSectionHeight();
    }

    setCardHeight(cardRowRef.current.offsetHeight)


  }, []);



  return (
    <>
    <ThemeProvider theme={darkTheme}>
      <a href="#portfolio"></a>
      <section id="portfolio" ref={portfolioSectionRef} className={`${css.hsPortfolio} hs-portfolio hs-section`} style={{padding: '7em 0', }}>
      <div className="container mb-4">

        <div className="row">
          <div className="col-lg-12 text-center text-color--light">
            <h2 className="hs-section-heading">Portfolio</h2>
            <h3 className="hs-section-subheading hs-text-muted">
              &#10038; Design System • Web Components • Websites • CRM &#10038;
            </h3>
          </div>
        </div>

        <div className="hs-divider d-flex align-items-center"></div>
        <Stack>
        <div ref={cardRowRef} className="portfolio-cards-grid">
          <div className="c1">
          <Card sx={{ maxWidth: 345, height: cardHeight + 33 , position: 'relative', background: '#fff' }}>
            <CardMedia
              sx={{ height: 140, cursor: 'pointer'}}
              image="/assets/img/portfolio/USAA/usaa-ema-01.jpg"
              title="USAA"
              data-bs-toggle="modal"
              data-bs-target="#usaaDetails"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h6">
              USAA Employee Mobile Apps
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>USAA </span><br/>
                  Banking * Insurance <br/>
                  Full Stack Software Engineer
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>Categoy: </span><br/>
              Productivity Apps for USAA Employees
              </Typography>
            </CardContent>
            <CardActions sx={{position: 'absolute', bottom: 0}}>
              <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#usaaDetails">More</button>
            </CardActions>
          </Card>
          </div>
          <div className="c2">
          <Card sx={{ maxWidth: 345, height: cardHeight + 33 , position: 'relative', background: '#fff' }}>
            <CardMedia
              sx={{ height: 140, cursor: 'pointer' }}
              image="/assets/img/portfolio/Fannie-Mae/blueprint-card-header.jpg"
              title="Fannie Mae"
              data-bs-toggle="modal"
              data-bs-target="#fanniemaeDetails"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h6">
               Fannie Mae Design System
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>
                    Fannie Mae, Inc.</span><br />
                    Interaction Designer<br />
                     - UI/UX Developer
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>Categoy: </span><br/>
                SCSS, CSS, Web Components &amp; Guides
              </Typography>
            </CardContent>
            <CardActions sx={{position: 'absolute', bottom: 0}}>
            <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#fannieMaeDetails">More</button>
            </CardActions>
          </Card>

          </div>
          <div className="c3">
          <Card sx={{ maxWidth: 345, height: cardHeight + 33, cursor: 'pointer' , position: 'relative', background: '#fff' }}>
            <CardMedia
              sx={{ height: 140 }}
              image="/assets/img/portfolio/BlueStarSports/BSS-card-heading.jpg"
              title="Blue Star Sports"
              data-bs-toggle="modal"
              data-bs-target="#bluestarDetails"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h6">
              USA Football Database
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>
               Blue Star Sports</span>
                  <br />
                  Data Services<br />
                  UI Software Engineer
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}>Category: </span><br/>
                SCSS, CSS, Web Components &amp; Guides
              </Typography>
            </CardContent>
            <CardActions sx={{position: 'absolute', bottom: 0}}>
            <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#bluestarDetails">More</button>
            </CardActions>
          </Card>

          </div>
          <div className="c4">
          <Card sx={{ maxWidth: 345, height: cardHeight + 33 , cursor: 'pointer', position: 'relative', bgcolor: '#fff' }}>
            <CardMedia
              sx={{ height: 140 }}
              image="/assets/img/portfolio/bowlopolis/bowlopolis-headerImg.jpg"
              title="Bowlopolis"
              data-bs-toggle="modal"
              data-bs-target="#bowlopolisDetails"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="h6">
              Bowlopolis - Youth Program
              </Typography>
              <Typography variant="caption" paragraph={true}>
              <span style={{fontWeight: 700}}> BPAA, Inc.</span><br />
                  Proprietor Association<br />
                  Director Technology - App Dev
              </Typography>
              <Typography variant="caption" paragraph={true}>
                <span style={{fontWeight: 700}}>Category:</span><br/>
              Kids 3D Cartoons, Videos & Games
              </Typography>
            </CardContent>
            <CardActions sx={{position: 'absolute', bottom: 0}}>
            <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#bowlopolisDetails">More</button>
            </CardActions>
          </Card>

          </div>
        </div>
        </Stack>

      </div>
     <FannieMaeContent/>
     <BlueStarSportsContent/>
     <BowlopolisContent/>
     <USAAContent/>
     <div className="c1">
      <PortfolioGallery/>
     </div>
    </section>
    </ThemeProvider>
    </>
  );
};


export default Portfolio;
