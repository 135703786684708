// esllint-disable react/no-unknown-property
import * as React from 'react'


export const USAAContent = () => {
  return (
    <>
      <div className="modal fade" id="usaaDetails" aria-labelledby="USAADetailsTitle" aria-hidden="true" title="USAA">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-11 mx-auto">
                  <h5 className="modal-title text-xl mr-3" id="USAADetailsTitle">USAA
                    <div className="text-center"
                      style={{display: 'inline-block', flex:' 1 1 auto', marginLeft: '20px', fontSize: '14px'}}>
                      Employee Mobile Apps 
                    </div>
                  </h5>
                </div>
              </div>
            </div>

            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button>
          </div>
          <div className="modal-body">
            <div className="container-sm">
              <div className="row">
                <div className="col-md-12 mx-auto">

                  <p className="hs-paragraph lead">
                    Mobile app development &amp; maintenance for the employees at USAA. The employee apps are data sensitive so what you see below is the commercial apps.
                  </p>
                  <div className="hs-paragraph">
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Company</strong>:</div>
                      <div className="col-md-10">United Services Automobile Association</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>Description </strong>:</div>
                      <div className="col-md-10"> Productivity apps for 35,000 employees world wide. </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2 info-title"><strong><span className="hs-text-yellow">@</span>My Roles</strong>:</div>
                      <div className="col-md-10">UI Engineering 
                        <span className="hs-text-yellow">&#10038;</span> Ops Developer <span className="hs-text-yellow">&#10038;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="hs-paragraph text-center pt-3">
                  <img className="banner img-centered" width="100%" height="auto" src="/assets/img/bg/usaa-mobile.jpg" alt="USAA Mobile Apps"/>
                </p>
              </div>
            </div>
            {/* <!-- end front face -->
            <!-- back face -->
            
            
            <!-- end back face --> */}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>

    </>
  )
}
export default USAAContent